import hero_slider_1 from "./images/hero-slider-1.jpg";
import hero_slider_2 from "./images/hero-slider-2.jpg";

import the_complete_2024_web_development_bootcamp from "./images/the_complete_2024_web_development_bootcamp.jpg";
import the_web_developer_bootcamp_2024 from "./images/the_web_developer_bootcamp_2024.jpg";
import the_complete_javascript_course_2024_from_zero_to_expert from "./images/the_complete_javascript_course_2024_from_zero_to_expert.jpg";
import ios_swift_the_complete_ios_app_development_bootcamp from "./images/ios_swift_the_complete_ios_app_development_bootcamp.jpg";
import flutter_dart_the_complete_guide_2024_edition from "./images/flutter_dart_the_complete_guide_2024_edition.jpg";
import react_native_the_practical_guide_2024 from "./images/react_native_the_practical_guide_2024.jpg";
import an_entire_mba_in_1_course_award_winning_business_school_prof from "./images/an_entire_mba_in_1_course_award_winning_business_school_prof.jpg";
import mba_in_a_box_business_lessons_from_a_ceo from "./images/mba_in_a_box_business_lessons_from_a_ceo.jpg";
import communication_fundamentals_how_to_communicate_better from "./images/communication_fundamentals_how_to_communicate_better.jpg";
import write_better_emails_tactics_for_smarter_team_communication from "./images/write_better_emails_tactics_for_smarter_team_communication.jpg";
import a_100_days_of_code_the_complete_python_pro_bootcamp from "./images/100_days_of_code_the_complete_python_pro_bootcamp.jpg";
import c_sharp_basics_for_beginners_learn_c_sharp_fundamentals_by_coding from "./images/c_sharp_basics_for_beginners_learn_c_sharp_fundamentals_by_coding.jpg";
import beginning_cpp_programming_from_beginner_to_beyond from "./images/beginning_c++_programming_from_beginner_to_beyond.jpg";
import leadership_practical_leadership_skills from "./images/leadership_practical_leadership_skills.jpg";
import become_a_product_manager_learn_the_skills_and_get_the_job from "./images/become_a_product_manager_learn_the_skills_and_get_the_job.jpg";
import introduction_to_finance_accounting_modeling_and_valuation from "./images/introduction_to_finance_accounting_modeling_and_valuation.jpg";
import accounting_financial_statement_analysis_complete_training from "./images/accounting_&_financial_statement_analysis_complete_training.jpg";
import blockchain_and_bitcoin_fundamentals from "./images/blockchain_and_bitcoin_fundamentals.jpg";
import cryptocurrency_investment_course_2024_fund_your_retirement from "./images/cryptocurrency_investment_course_2024_fund_your_retirement.jpg";
import the_complete_investment_banking_course_2024 from "./images/the_complete_investment_banking_course_2024.jpg";
import finance_for_non_finance_learn_quick_and_easy from "./images/finance_for_non_finance_learn_quick_and_easy.jpg";
import ultimate_aws_certified_solutions_architect_associate_saa_c03 from "./images/ultimate_aws_certified_solutions_architect_associate_saa_c03.jpg";
import new_ultimate_aws_certified_cloud_practitioner_clf_c02 from "./images/new_ultimate_aws_certified_cloud_practitioner_clf_c02.jpg";
import az_900_microsoft_azure_fundamentals_exam_prep_oct_2023 from "./images/az_900_microsoft_azure_fundamentals_exam_prep_oct_2023.jpg";
import learn_ethical_hacking_from_scratch from "./images/learn_ethical_hacking_from_scratch.jpg";
import the_complete_cyber_security_course_hackers_exposed from "./images/the_complete_cyber_security_course_hackers_exposed.jpg";
import microcontroller_embedded_c_programming_absolute_beginners from "./images/microcontroller_embedded_c_programming_absolute_beginners.jpg";
import arduino_step_by_step_getting_started from "./images/arduino_step_by_step_getting_started.jpg";
import plc_fundamentals_level_i from "./images/plc_fundamentals_level_i.jpg";
import angular_the_complete_guide_2024_edition from "./images/angular_the_complete_guide_2024_edition.jpg";
import build_responsive_real_world_websites_with_html_and_css from "./images/build_responsive_real_world_websites_with_html_and_css.jpg";
import modern_react_with_redux_2024_update from "./images/modern_react_with_redux_2024_update.jpg";
import the_complete_web_developer_course_3_0 from "./images/the_complete_web_developer_course_3.0.jpg";
import vue_the_complete_guide_incl_router_composition_api from "./images/vue_the_complete_guide_incl_router_composition_api.jpg";
import python_and_django_full_stack_web_developer_bootcamp from "./images/python_and_django_full_stack_web_developer_bootcamp.jpg";
import understanding_typescript from "./images/understanding_typescript.jpg";
import javascript_understanding_the_weird_parts from "./images/javascript_understanding_the_weird_parts.jpg";
import advanced_css_and_sass_flexbox_grid_animations_and_more from "./images/advanced_css_and_sass_flexbox_grid_animations_and_more.jpg";
import html_and_css_for_beginners_build_a_website_and_launch_online from "./images/html_and_css_for_beginners_build_a_website_and_launch_online.jpg";
import empty_shopping_cart from "./images/empty-shopping-cart-v2.jpg";

import logo_udemy from "./images/logo-udemy.svg";
import logo_udemy_inverted from "./images/logo-udemy-inverted.svg";

import lohp_category_development_v2 from "./images/lohp-category-development-v2.jpg";
import lohp_category_business_v2 from "./images/lohp-category-business-v2.jpg";
import lohp_category_it_and_software_v2 from "./images/lohp-category-it-and-software-v2.jpg";

const category_images = {
  lohp_category_development_v2,
  lohp_category_business_v2,
  lohp_category_it_and_software_v2,
}

const hero_images = { hero_slider_1, hero_slider_2 };

const course_images = {
  the_complete_2024_web_development_bootcamp,
  the_web_developer_bootcamp_2024,
  the_complete_javascript_course_2024_from_zero_to_expert,
  ios_swift_the_complete_ios_app_development_bootcamp,
  flutter_dart_the_complete_guide_2024_edition,
  react_native_the_practical_guide_2024,
  an_entire_mba_in_1_course_award_winning_business_school_prof,
  mba_in_a_box_business_lessons_from_a_ceo,
  communication_fundamentals_how_to_communicate_better,
  write_better_emails_tactics_for_smarter_team_communication,
  a_100_days_of_code_the_complete_python_pro_bootcamp,
  c_sharp_basics_for_beginners_learn_c_sharp_fundamentals_by_coding,
  beginning_cpp_programming_from_beginner_to_beyond,
  leadership_practical_leadership_skills,
  become_a_product_manager_learn_the_skills_and_get_the_job,
  introduction_to_finance_accounting_modeling_and_valuation,
  accounting_financial_statement_analysis_complete_training,
  blockchain_and_bitcoin_fundamentals,
  cryptocurrency_investment_course_2024_fund_your_retirement,
  the_complete_investment_banking_course_2024,
  finance_for_non_finance_learn_quick_and_easy,
  ultimate_aws_certified_solutions_architect_associate_saa_c03,
  new_ultimate_aws_certified_cloud_practitioner_clf_c02,
  az_900_microsoft_azure_fundamentals_exam_prep_oct_2023,
  learn_ethical_hacking_from_scratch,
  the_complete_cyber_security_course_hackers_exposed,
  microcontroller_embedded_c_programming_absolute_beginners,
  arduino_step_by_step_getting_started,
  plc_fundamentals_level_i,
  angular_the_complete_guide_2024_edition,
  build_responsive_real_world_websites_with_html_and_css,
  modern_react_with_redux_2024_update,
  the_complete_web_developer_course_3_0,
  vue_the_complete_guide_incl_router_composition_api,
  python_and_django_full_stack_web_developer_bootcamp,
  understanding_typescript,
  javascript_understanding_the_weird_parts,
  advanced_css_and_sass_flexbox_grid_animations_and_more,
  html_and_css_for_beginners_build_a_website_and_launch_online
}

export { hero_images, course_images, logo_udemy, logo_udemy_inverted, category_images, empty_shopping_cart };